




































































import { Component, Mixins } from "vue-property-decorator"

@Component
export default class GameVersionModal extends Mixins() {
  isOpen = false
  gameVersion: Nullable<GameVersion> = null
  filter = null
  filterOn = ["id", "name"]
  currentPage = 1
  perPage = 10
  totalRows = 1

  get tableColumns() {
    return [
      { key: "id" },
      { key: "name" },
      { key: "patchId" },
      { key: "download" }
    ]
  }

  async mounted() {
    this.$root.$on("openGameVersionModal", async (payload: GameVersion) => {
      this.isOpen = true
      this.gameVersion = payload
      this.totalRows = this.gameVersion?.files.length || 1
    })
  }

  onFiltered(filteredItems) {
    this.totalRows = filteredItems.length
    this.currentPage = 1
  }
}
